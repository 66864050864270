/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { DeepReadonly } from 'vue';
import { z } from 'zod';
import { ZodDeprecatedRightEnum } from '@/models/user/DeprecatedRightEnum';
import { RoleEnum, ZodRoleEnum } from '@/models/user/RoleEnum';
import colors from '@/assets/theme/colors.module.scss';

// TODO: implement the changes described in story #230478, when the migration to new Roles Concept has been finished.

const Role = z
  .object({
    name: ZodRoleEnum.or(z.string()), // also allow any string, so unknown keys will not break the whole CC
    rights: z.array(ZodDeprecatedRightEnum.or(z.string())).optional(),
    brandId: z.number(),
  })
  .transform(({ name, rights, brandId }) => ({
    name,
    rights,
    brandId,
    global: brandId === 0,
  }));
type Role = z.infer<typeof Role>;

export const color = (role: DeepReadonly<Role>) => {
  const globalRoleColor = colors.BlueSky;
  const brandRoleColor = colors.UglyGreen;
  if (role.name === 'CONTROL_CENTER_USER_MANAGER') {
    return colors.TwilightHover;
  }
  if (role.brandId === 0) {
    return globalRoleColor;
  } else {
    return brandRoleColor;
  }
};

export const abbreviation = (role: DeepReadonly<Role>) => {
  // permission roles
  if (role.name.startsWith('PERM_')) {
    return 'PERM';
  }

  switch (role.name) {
    // brands
    case RoleEnum.BRAND_VW:
      return 'VW';
    case RoleEnum.BRAND_PORSCHE:
      return 'Porsche';
    case RoleEnum.BRAND_SKODA:
      return 'SKODA';
    case RoleEnum.BRAND_SEAT:
      return 'SEAT';
    case RoleEnum.BRAND_CUPRA:
      return 'CUPRA';
    case RoleEnum.BRAND_VWN:
      return 'VWN';
    case RoleEnum.BRAND_AUDI:
      return 'Audi';
    case RoleEnum.BRAND_MAN:
      return 'MAN';

    // joint ventures
    case RoleEnum.JV_SAIC:
      return 'SAIC';
    case RoleEnum.JV_FAW:
      return 'FAW';
    case RoleEnum.JV_VWA:
      return 'VWA';

    // regions
    case RoleEnum.RGN_CANADA:
      return 'Canada';
    case RoleEnum.RGN_NORTH_AMERICA:
      return 'North America';
    case RoleEnum.RGN_EUROPE:
      return 'Europe';
    case RoleEnum.RGN_CHINA:
      return 'China';

    // update technologies
    case RoleEnum.UT_CU:
      return 'CU';
    case RoleEnum.UT_WEBAPP:
      return 'WebApp';
    case RoleEnum.UT_ORU_MEB:
      return 'ORU MEB';
    case RoleEnum.UT_ORU_2_3:
      return 'ORU 2/3';
    case RoleEnum.UT_ORU_NEXT:
      return 'ORU next';
    case RoleEnum.UT_DRM:
      return 'DRM';
    case RoleEnum.UT_ORU_2_0:
      return 'ORU 2.0';
    case RoleEnum.UT_CMT:
      return 'CMT';

    // vehicle platforms
    case RoleEnum.VP_MQB_MLB:
      return 'MQB/MLB';
    case RoleEnum.VP_E311:
      return 'E³1.1';
    case RoleEnum.VP_E312:
      return 'E³1.2';
    case RoleEnum.VP_E320:
      return 'E³2.0';

    // job titles
    case RoleEnum.DATA_LOAD_MANAGER:
      return 'DLM';
    case RoleEnum.DATA_LOAD_ADMIN:
      return 'DLA';
    case RoleEnum.ANALYST:
      return 'A';
    case RoleEnum.CONTROL_CENTER_USER_MANAGER:
      return 'CCUM';
    case RoleEnum.DRM_MANAGER:
      return 'DRMM';
    case RoleEnum.VEHICLE_LIST_MANAGER:
      return 'VLM';
    case RoleEnum.ORU_2_0_MANAGER:
      return 'ORU20M';
    case RoleEnum.CMT_FUNCTION_OWNER:
      return 'CMTFU';
    case RoleEnum.CMT_DEPLOYMENT_MANAGER:
      return 'CMTDM';
    case RoleEnum.CAMPAIGN_MANAGER:
      return 'CM';
    case RoleEnum.CONTAINER_PO:
      return 'CPO';
    case RoleEnum.LAUNCH_OPERATOR:
      return 'LO';
    case RoleEnum.TEST_MANAGER:
      return 'TM';
    case RoleEnum.TEST_VEHICLE_MANAGER:
      return 'TVM';
    case RoleEnum.DEPLOYMENT_DATA_VIEWER:
      return 'DDV';
    case RoleEnum.DEPLOYMENT_DATA_PROVIDER:
      return 'DDP';
    case RoleEnum.BASELINE_UPDATE_MANAGER:
      return 'BLUM';

    case RoleEnum.ORU23_DATA_PROVIDER:
      return 'ODP';

    case RoleEnum.GLOBAL_CM_E3_2_0_ORU_MANAGER:
      return 'E³2.0';

    case RoleEnum.SUPERSET_ADMIN:
    case RoleEnum.ADMIN:
      return 'SA';

    case RoleEnum.CM_TEST_VIN_MANAGER:
      return 'TVM';

    //keycloak default roles
    case RoleEnum.KC_DEFAULT_ROLE_CC:
    case RoleEnum.KC_CREATE_CLIENT:
    case RoleEnum.KC_CREATE_REALM:
    case RoleEnum.KC_DEFAULT_ROLES_MASTER:
    case RoleEnum.KC_DELETE_ACCOUNT:
    case RoleEnum.KC_IMPERSONATION:
    case RoleEnum.KC_MANAGE_ACCOUNT:
    case RoleEnum.KC_MANAGE_ACCOUNT_LINKS:
    case RoleEnum.KC_MANAGE_AUTHORIZATION:
    case RoleEnum.KC_MANAGE_CLIENTS:
    case RoleEnum.KC_MANAGE_CONSENT:
    case RoleEnum.KC_MANAGE_EVENTS:
    case RoleEnum.KC_MANAGE_IDENTITY_PROVIDERS:
    case RoleEnum.KC_MANAGE_REALM:
    case RoleEnum.KC_MANAGE_USERS:
    case RoleEnum.KC_OFFLINE_ACCESS:
    case RoleEnum.KC_QUERY_CLIENTS:
    case RoleEnum.KC_QUERY_GROUPS:
    case RoleEnum.KC_QUERY_REALMS:
    case RoleEnum.KC_QUERY_USERS:
    case RoleEnum.KC_REALM_ADMIN:
    case RoleEnum.KC_UMA_AUTHORIZATION:
    case RoleEnum.KC_USER:
    case RoleEnum.KC_VIEW_APPLICATIONS:
    case RoleEnum.KC_VIEW_AUTHORIZATION:
    case RoleEnum.KC_VIEW_CLIENTS:
    case RoleEnum.KC_VIEW_CONSENT:
    case RoleEnum.KC_VIEW_EVENTS:
    case RoleEnum.KC_VIEW_IDENTITY_PROVIDERS:
    case RoleEnum.KC_VIEW_PROFILE:
    case RoleEnum.KC_VIEW_REALM:
    case RoleEnum.KC_VIEW_USERS:
    case RoleEnum.READ_TOKEN:
      return 'KEY';
  }
  return '?';
};

export const displayName = (role: DeepReadonly<Role>): string => {
  if (!role.name) return '???';
  return role.name.replaceAll('_', ' ');
};

export const sortByImportance = (r1: Role, r2: Role): number => {
  const roles: string[] = Object.keys(RoleEnum);
  const r1i = roles.findIndex((rName) => rName === r1.name);
  const r2i = roles.findIndex((rName) => rName === r2.name);
  return (r1i ? r1i : 0) - (r2i ? r2i : 0);
};

export const getColorOfHighestRole = (roles: Role[]): string => {
  if (roles.length === 0) {
    return '#666666';
  }
  const color1 = color([...roles].sort(sortByImportance)[0]);
  if (!color1) {
    return '#666669';
  }
  return color1;
};

export default Role;
